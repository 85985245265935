import React, { Component } from 'react'
import HeaderUi from './Header.container'
import LeftNavUi from './leftNav.container'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LocalStore from '../../../utils/localStorageUtil'
import { SignInApi, getUserApi, tableDataAPi, logoutHandler } from '../ActionCreator'

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            caHeaderHeight: 0
        }
    }

    componentDidMount() {
        if (LocalStore.getFromLocalStorage('login') !== 'success') {
            this.props.logoutHandler(this.props.history)
        }
        this.getUserMethod()
        this.getToken()
        this.getHeaderHeight()
    }

    getToken() {
        if (this.props.LoginData.length <= 0) {
            let userName = LocalStore.getFromLocalStorage('adminUserName')
            let password = LocalStore.getFromLocalStorage('adminPassword')
            this.props.SignInApi(userName, password,
                callBack => {

                })
        }
    }

    getUserMethod() {
        this.props.getUserApi(error => {
            if (error) {
                this.logoutHandler()
            }
        }, callBack => {
            this.getTableData(callBack.data)
        })
    }

    getTableData(data) {
        this.props.tableDataAPi(data, callBack => {

        }, error => {
            this.props.logoutHandler(this.props.history)
        })
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    getHeaderHeight() {
        if (document.getElementById("caHeader") !== null) {
            this.setState({
                caHeaderHeight: document.getElementById("caHeader").offsetHeight
            })
        }

    }

    componentDidUpdate() {
        if (document.getElementById("caHeader") !== null) {
            if (document.getElementById("caHeader").offsetHeight !== this.state.caHeaderHeight) {
                this.getHeaderHeight()
            }
        }
    }

    render() {
        return (
            <div className="container-fluid dashboard-container">
                <HeaderUi
                    history={this.props.history}
                    logoutHandler={this.logoutHandler.bind(this)}
                />
                <div className="row dashboard-body" style={{ height: 'calc(100% - ' + (this.state.caHeaderHeight) + 'px)' }}>
                    <LeftNavUi />
                    {this.state.hasError ?
                        <h1>Error occurred!</h1>
                        :
                        <div className="dashboard-body-section">{this.props.children}</div>
                    }
                </div>
            </div>
        )
    }

    logoutHandler() {
        this.props.logoutHandler(this.props.history)
    }

    chargePopupHandler() {
        this.setState({

        })
    }

}

const mapStateToProps = ({ LoginData, UserData }) => {
    return {
        LoginData: LoginData,
        UserData: UserData
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        SignInApi,
        getUserApi,
        tableDataAPi,
        logoutHandler
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);