import React from 'react';

const ResetPasswordPopup = ({ resetPasswordChange, resetPassword, resetPasswordHandler,
    btnDisabled, saveResetPasswordHandler }) => (
        <div className="patient-info contact-popup container-fluid" style={{ maxHeight: '255px' }}>
            <div className="row popup-header">
                <div className="col-md-6 col-sm-12">
                    <h5 className="m-0">
                        <b>{"Reset Password"}</b>
                    </h5>
                </div>
                <div className="col-md-6 col-sm-12">
                    <button className="close" onClick={e => resetPasswordHandler('reset')}>&times;</button>
                </div>
            </div>
            {/* <div className="row form-group">
                <div className="col-12">
                    <label className="font-12px m-0">UserName:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={resetPassword.userName}
                        placeholder="UserName"
                        onChange={e => resetPasswordChange(e, 'userName')}
                        readOnly
                    />
                </div>
            </div> */}
            <div className="row form-group">
                <div className="col-12">
                    <label className="font-12px m-0">New Password:</label>
                    <input
                        type="password"
                        className="form-control"
                        value={resetPassword.password}
                        placeholder="New Password"
                        onChange={e => resetPasswordChange(e, 'password')}
                    />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-12">
                    <label className="font-12px m-0">Confirm Password:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={resetPassword.confirmPassword}
                        placeholder="Confirm Password"
                        onChange={e => resetPasswordChange(e, 'confirmPassword')}
                    />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-12 inline-right-btn">
                    <button className="plain-btn save-btn" onClick={e => saveResetPasswordHandler(e)} disabled={btnDisabled}>Save</button>
                </div>
            </div>
            {/* <img className="" style={{maxWidth: '100%', maxHeight: 'calc(100% - 55px)', margin: '0 auto', display: "block"}} src={ComingSoon} alt={"img"} /> */}
        </div>
    )

export default ResetPasswordPopup;
