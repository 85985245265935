import { combineReducers } from 'redux';
import { OnLoginData as LoginData } from '../components/container/reducer';
import { OnUserData as UserData } from '../components/container/reducer';
import { OnTableData as TableData } from '../components/container/reducer';
import { OnFaxInbocData as inboxFaxData } from '../components/container/reducer';
import { OnFaxLabelData as labelFaxData } from '../components/container/reducer';
import { OnLoginUserData as loginUserData } from '../components/container/reducer'


export default combineReducers({
    LoginData,
    UserData,
    TableData,
    inboxFaxData,
    labelFaxData,
    loginUserData
});