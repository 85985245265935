const COLORS = {
    THEME_COLOR: '#0c8efa',
    WHITE_COLOR: '#ffffff',
    HIGHLIGHT_ROW: '#c4e8ec',
    IMP_ROW: '#fdf9e6',
    INBOX_HEADER_BG: '#e7f8ff',
    RED: '#f34330',
    BLUE: '#337ab7',
    GREEN: '#6ac258'
}

const ACTION = {
    IN_PROGRSS: 'InProgress',
    INSERT_EHR: 'Insert EHR',
    NEW: "New"
}

const ROLES = {
    ADMIN: '1',
    USER: '2',
    SYSTEM_ADMIN:'34',
    CORP_ADMIN:'35',
    NEW: "New"
}

export {
    ACTION,
    ROLES,
    COLORS
}