import React from 'react';
import Logo from '../../../images/logo.png'
import UserImg from '../../../images/user.png'

const App = ({ logoutHandler, userName }) => (
  <div className="row dashboard-header" id="caHeader">
    <span className="d-flex">
      <img className="img-responsive logo-img" src={Logo} alt="logo-img" />
      <h2 className="header-text"><b>Falkon</b> Data</h2>
    </span>
    <span className="dashboard-user">
      <span className="dropdown">
        <button className="dropdown-toggle pointer trans-btn" data-toggle="dropdown">
          <img className="img-responsive user-img" src={UserImg} alt="user-img" />
          <p className="header-text">{userName}</p>
        </button>
        <ul className="dropdown-menu pointer header-menu">
          <li onClick={e => logoutHandler()}><i className="fa fa-sign-out" />&nbsp;&nbsp;&nbsp;Log out</li>
        </ul>
      </span>
    </span>
  </div>
)

export default App;
