export default {
    LOGIN: '/',
    SECOND: '/second',
    HOME: '/home',
    DATA: '/home/data',
    INBOX: '/inbox',
    MSG_DETAIL: '/msgDetail',
    SSO: '/sso',
    USER_MANAGEMENT: '/userManagement',
    DIRECTORY_MANAGEMENT: '/directoryManagement',
    CORPACCOUNT: '/corpaccount',
    ACCOUNT: '/account',
    PROFILE: '/profile',
    REPORTS: '/reports',
    SMART_REFERRAL_LINK: '/reffer/:username',
    ANALYTICS: '/analytics'
}