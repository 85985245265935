import React from 'react'
export const LoginPage = React.lazy(() => import('../components/container/Login.container'));
export const dashboardData = React.lazy(() => import('../components/container/MainData.container'));
export const inboxPage = React.lazy(() => import('../components/container/SMA/Inbox.container'));
export const msgDetailPage = React.lazy(() => import('../components/container/SMA/MsgDetail.container'));
export const SSOPage = React.lazy(() => import('../components/container/SMA/Sso.container'));
export const userManagementPage = React.lazy(() => import('../components/container/SMA/UserManagement.container'));
export const directoryManagementPage = React.lazy(() => import('../components/container/SMA/DirectoryManagement.container'));
export const corpAccountPage = React.lazy(() => import('../components/container/SMA/CorpAccount.container'));
export const accountPage = React.lazy(() => import('../components/container/SMA/Account.container'));
export const profilePage = React.lazy(() => import('../components/container/SMA/Profile.container'));
export const sendPinPage = React.lazy(() => import('../components/container/SMA/SendPin.container'));
export const analyticsPage = React.lazy(() => import('../components/container/SMA/Analytics.container'));

export const reportPage = React.lazy(() => import('../components/container/Reports/ReportMain.container'));
