import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'jquery';
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import AppRouter from './Layout/App.Router';
import 'font-awesome/css/font-awesome.min.css';
import * as serviceWorker from './serviceWorker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-table/react-table.css";
require('dotenv').config()

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
});

ReactDOM.render(
    <AppRouter />,
    document.getElementById('oataikApp')
);

serviceWorker.unregister();
