const APIS = {
    GET_USER: window['runConfig'].REACT_APP_BASE_URL + 'user/account',
    USER_PASSWORD: window['runConfig'].REACT_APP_BASE_URL + 'user/password',
    USER_ATTRIBUTE: window['runConfig'].REACT_APP_BASE_URL + 'user/attribute',
    GET_LOGIN_USER: window['runConfig'].REACT_APP_BASE_URL + 'user',
    TABLE_DATA: window['runConfig'].REACT_APP_BASE_URL + 'charge/filelog?accountid=',
    UPLOAD_CHARGE: window['runConfig'].REACT_APP_BASE_URL + 'charge/document',
    PATIENT_DATA: window['runConfig'].REACT_APP_BASE_URL + 'charge/filelog/',

    INBOX_PAGE_LABEL: window['runConfig'].REACT_APP_BASE_URL + 'sma/label/count?userid=',
    FAX_DOCUMENT_LIST: window['runConfig'].REACT_APP_BASE_URL + 'sma/document?filter=',
    INBOX_GET_DOC_ACTION: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/action?accountid=',
    SENT_GET_DOC_ACTION: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/sentitems/status?accountid=',
    IN_PROGRESS_ACTION: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/action',
    SAVE_UPDATE_DIRECTORY: window['runConfig'].REACT_APP_BASE_URL + 'sma/directory',
    INBOX_GET_DIRECTORY: window['runConfig'].REACT_APP_BASE_URL + 'sma/directory?accountid=',
    SAVE_NAME_DOB: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/',
    SEND_MSG_DOC: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/send?type=',
    RESEND_MSG: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/resend?accountid=',
    SENT_ITEMS: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/sentitems?accountid=',
    VIEW_TEXT_PDF: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/',
    SEND_FAX_PREVIEW: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/preview?accountid=',
    LINK_DOCUMENT_LABEL: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/label',
    SETTING_APPS: window['runConfig'].REACT_APP_BASE_URL + 'settings/apps?accountid=',
    LOOKUP_PATIENT: window['runConfig'].REACT_APP_BASE_URL + "fhir/api/patient/", 
    LOOKUP_CARRIER: window['runConfig'].REACT_APP_BASE_URL + "fhir/api/carrier/", 
    UPLOAD_EHR_DOC: window['runConfig'].REACT_APP_BASE_URL + "fhir/api/EHRDocument/upload",
    CREATE_LABEL: window['runConfig'].REACT_APP_BASE_URL + 'sma/label/rule',
    SET_DOC_PRIORITY: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/priority',
    SET_DOC_READ_STATUS: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/read',

    GET_ALL_USERS: window['runConfig'].REACT_APP_BASE_URL + 'user/getall?accountid=',
    GET_ALL_LABELS: window['runConfig'].REACT_APP_BASE_URL + 'sma/label?accountid=',
    GET_USER_INFO: window['runConfig'].REACT_APP_BASE_URL + 'user/',
    CREATE_DRAFT: window['runConfig'].REACT_APP_BASE_URL + "sma/document/draft?userid=",
    FETCH_DRAFT: window['runConfig'].REACT_APP_BASE_URL + "sma/document/draft?accountid=",
    GET_LABEL_INFO: window['runConfig'].REACT_APP_BASE_URL + 'sma/label/',
    SMART_REFFERAL_DRAFT: window['runConfig'].REACT_APP_BASE_URL + 'sma/smartreferal/draft?accountid=',
    SMART_REFFERAL_SEND: window['runConfig'].REACT_APP_BASE_URL + 'sma/smartreferal?accountid=',
    VERIFY_SENDER: window['runConfig'].REACT_APP_BASE_URL + 'sma/smartreferal/verifysender?directoryid=',
    GET_HEADER_ANALYTICS: window['runConfig'].REACT_APP_BASE_URL + 'sma/dashboard/stats?accountid=',
    GET_TOPTEN_ANALYTICS: window['runConfig'].REACT_APP_BASE_URL + 'sma/dashboard/toptens?accountid=',
    GET_CONVERSATION: window['runConfig'].REACT_APP_BASE_URL + 'sma/document/conversation?documentid=',
    GET_CORP_ACCOUNTS: window['runConfig'].REACT_APP_BASE_URL + 'sma/admin/corpaccount',
    GET_ACCOUNTS: window['runConfig'].REACT_APP_BASE_URL + 'sma/admin/account?corpaccountid=',
    CREATE_ACCOUNTS: window['runConfig'].REACT_APP_BASE_URL + 'sma/admin/account',
    UPDATE_ACCOUNTS: window['runConfig'].REACT_APP_BASE_URL + 'sma/admin/account',
    //CREATE_CORP_ACCOUNTS: window['runConfig'].REACT_APP_BASE_URL + 'sma/admin/corpaccount',
    REPROCESS_RULES: window['runConfig'].REACT_APP_BASE_URL + 'sma/reprocessrules',
    GET_REPORT_NAMES: window['runConfig'].REACT_APP_BASE_URL + 'rpt',
    REFERRAL_PATIENT: window['runConfig'].REACT_APP_BASE_URL + 'fhir/api/patient/'
}

export {
    APIS
}

//process.env