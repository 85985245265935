import React, { Component } from 'react'
import DashboardLeftNavUi from '../../presentational/DashboardLayout/LeftNav.component'

class DashboardLeftNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <DashboardLeftNavUi
            
            />
        )
    }

}

export default DashboardLeftNav;