import React, { Component } from 'react'
import CreateLabelUi from '../../presentational/SMA/AssignLabel.popup'
import {
    updateUserInfoApi, updateUserInfoInAWS, createAwsUser,
    getUserInfoApi, getAllLabelsApi, ResetPassword
} from '../ActionCreator'
import { notify } from '../../../utils/commonUtils'
import Loader from '../../presentational/Loader/Loader'

class CreateLabelPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            editUserData: {
                userName: '',
                password: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                dob: new Date()

            },
            showLabelsCheckboxes: false,
            editLabelRoles: {
                labelsForAssign: [],
                labelNameForAssign: [],
                roles: []
            },
            loaderState: false,
            allLabels: []
        }
    }

    render() {
        return (
            <>
                <CreateLabelUi
                    labelPopupHandler={this.labelPopupHandler.bind(this)}
                    createLabel={this.state.editUserData}
                    assignLabelChange={this.assignLabelChange.bind(this)}
                    allLabels={this.state.allLabels}
                    userChange={this.userChange.bind(this)}
                    showLabelsHandler={this.showLabelsHandler.bind(this)}
                    showLabelsCheckboxes={this.state.showLabelsCheckboxes}
                    editLabelRoles={this.state.editLabelRoles}
                    popupType={this.props.popupType}
                    loginUserData={this.props.loginUserData}
                />
                {this.state.loaderState ?
                    <Loader />
                    : null}
            </>
        )
    }

    componentDidMount() {
        const { popupType } = this.props
        if (popupType === 'edit') {
            this.getUserInfoHandler()
        }
        this.getAllLabelsHandler()
    }

    getAllLabelsHandler() {
        const { UserData } = this.props
        let accIds = []
        if (UserData !== undefined) {
            for (let i = 0; i < UserData.length; i++) {
                accIds.push(UserData[i].accountNumber)
            }
        }
        getAllLabelsApi(
            accIds,
            UserData,
            callBack => {
                this.setState({
                    allLabels: callBack.data !== undefined ? callBack.data : []
                })
            }, e => {

            })
    }

    getUserInfoHandler() {
        const { labelPopupData } = this.props
        this.setState({ loaderState: true })
        getUserInfoApi(labelPopupData.userName,
            callBack => {
                this.setState({
                    userData: callBack.data[0],
                    loaderState: false
                }, () => {
                    let editUserData = Object.assign({}, this.state.editUserData)
                    let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
                    editUserData.userName = this.state.userData.userName
                    editUserData.firstName = this.state.userData.firstName
                    editUserData.lastName = this.state.userData.lastName
                    editUserData.email = this.state.userData.email
                    editUserData.phone = this.state.userData.phone
                    editUserData.dob = new Date(this.state.userData.dob)
                    if (this.state.userData.roles !== null) {
                        editLabelRoles.roles = [this.state.userData.roles]
                    }
                    if (this.state.userData.labels !== null) {
                        editLabelRoles.labelsForAssign = this.state.userData.labels.split(",")
                    }
                    this.setState({
                        editUserData,
                        editLabelRoles
                    }, () => {
                        this.getLabelNameHandler()
                    })
                })
            }, e => {

            })
    }

    getLabelNameHandler() {
        let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
        editLabelRoles.labelNameForAssign = []
        this.state.allLabels.forEach(data => {
            if (this.state.editLabelRoles.labelsForAssign.includes(data.id.toString())) {
                if (!editLabelRoles.labelNameForAssign.includes(data.name)) {
                    editLabelRoles.labelNameForAssign.push(data.name)
                }
            }
        })
        this.setState({
            editLabelRoles
        })
    }

    showLabelsHandler(e, type) {
        e.stopPropagation()
        if (type === 'inner') {
            this.setState({ showLabelsCheckboxes: !this.state.showLabelsCheckboxes })
        } else {
            this.setState({ showLabelsCheckboxes: false })
        }
    }

    userChange(e, type) {
        let editUserData = Object.assign({}, this.state.editUserData)
        if (type === 'userName') {
            editUserData.userName = e.target.value
        } else if (type === 'password') {
            editUserData.password = e.target.value
        } else if (type === 'firstName') {
            editUserData.firstName = e.target.value
        } else if (type === 'lastName') {
            editUserData.lastName = e.target.value
        } else if (type === 'email') {
            editUserData.email = e.target.value
        } else if (type === 'phone') {
            editUserData.phone = e.target.value
        } else if (type === 'dob') {
            editUserData.dob = e
        } else if (type === 'roles') {
            editUserData.roles = [e.target.value]
        }
        this.setState({
            editUserData
        })
    }

    assignLabelChange(e, type) {
        let editLabelRoles = Object.assign({}, this.state.editLabelRoles)
        if (type === 'labels') {
            if (e.target.checked) {
                if (!editLabelRoles.labelsForAssign.includes(e.target.value)) {
                    editLabelRoles.labelsForAssign.push(e.target.value)
                }
            } else {
                let index = editLabelRoles.labelsForAssign.indexOf(e.target.value)
                editLabelRoles.labelsForAssign.splice(index, 1)
            }
        } else if (type === 'roles') {
            editLabelRoles.roles = [e.target.value]
        }
        this.setState({ editLabelRoles }, () => {
            this.getLabelNameHandler()
        })
    }

    labelPopupHandler(e, type) {
        e.stopPropagation()
        const { UserData } = this.props
        if (UserData !== undefined) {
            let accIds = []
            for (let i = 0; i < UserData.length; i++) {
                accIds.push(UserData[i].accountNumber)
            }
            if (type === 'save') {
                this.setState({ loaderState: true })
                if (this.props.popupType === 'edit') {
                    updateUserInfoInAWS(
                        this.state.editUserData, 
                        accIds.join().toString(), 
                        UserData[0].corpAccountNumber.toString(),
                        callBack => {
                            if (this.state.editLabelRoles.labelsForAssign.length > 0) {
                                updateUserInfoApi(
                                    accIds,
                                    UserData,
                                    this.state.userData.userId,
                                    this.state.editLabelRoles,
                                    callBack => {
                                        this.setState({
                                            loaderState: false
                                        })
                                        notify('success', callBack.data)
                                        this.props.labelPopupHandler('callApi')
                                    }, e => {

                                    })
                            } else {
                                this.setState({
                                    loaderState: false
                                })
                                this.props.labelPopupHandler('callApi')
                            }
                        }, error => {

                        })
                } else if (this.props.popupType === 'create') {
                    createAwsUser(
                        this.state.editUserData,
                        accIds.join().toString(),
                        UserData[0].corpAccountNumber.toString(),
                        callBack => {
                            if (callBack.User.UserStatus === "FORCE_CHANGE_PASSWORD") {
                                ResetPassword(
                                    this.state.editUserData.userName,
                                    this.state.editUserData.password,
                                    accIds.join().toString(),
                                    UserData[0].corpAccountNumber.toString(),
                                    callBack2 => {
                                        this.setState({
                                            loaderState: false
                                        })
                                        if (this.state.editLabelRoles.labelsForAssign.length > 0) {
                                            this.setState({
                                                loaderState: true
                                            })
                                            getUserInfoApi(callBack.User.Username, userInfoCallBack => {
                                                updateUserInfoApi(
                                                    accIds,
                                                    UserData,
                                                    userInfoCallBack.data[0].userId,
                                                    this.state.editLabelRoles,
                                                    callBack => {
                                                        this.setState({
                                                            loaderState: false
                                                        })
                                                        notify('success', callBack.data)
                                                        this.props.labelPopupHandler('callApi')
                                                    }, e => {

                                                    })
                                            }, e => {

                                            })
                                        } else {
                                            this.setState({
                                                loaderState: false
                                            })
                                            this.props.labelPopupHandler('callApi')
                                        }
                                    }, error => {

                                    })
                            }
                        }, error => {
                            this.setState({
                                loaderState: false
                            })
                            notify('error', error.toString())
                        })
                }
            } else {
                this.props.labelPopupHandler()
            }
        }
    }

}

export default CreateLabelPopup;