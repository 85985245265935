import React from 'react';
import DatePicker from 'react-date-picker';
import { ROLES } from '../../../utils/constant'

const CreateLabelPopup = ({ assignLabelChange, createLabel, labelPopupHandler, allLabels,
    userChange, showLabelsHandler, showLabelsCheckboxes, editLabelRoles, popupType,
    loginUserData }) => (
        <div className="patient-info edit-user-info-popup container-fluid" onClick={e => showLabelsHandler(e, 'outer')}>
            <div className="row popup-header">
                <div className="col-md-6 col-sm-12">
                    <h5 className="m-0">
                        {popupType === 'edit' ?
                            <b>{"Edit user details (" + createLabel.userName + ')'}</b>
                            :
                            <b>{"Create User"}</b>
                        }
                    </h5>
                </div>
                <div className="col-md-6 col-sm-12">
                    <button className="close" onClick={e => labelPopupHandler(e, 'close')}>&times;</button>
                </div>
            </div>
            {popupType === 'create' ?
                <div className="row form-group">
                    <div className="col-12 col-md-6">
                        <label className="font-12px m-0">UserName</label>
                        <input type="text" className="form-control" value={createLabel.userName} onChange={e => userChange(e, 'userName')} autoComplete={"username"} />
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="font-12px m-0">Password</label>
                        <input type="password" className="form-control" value={createLabel.password} onChange={e => userChange(e, 'password')} autoComplete={"new-password"} />
                    </div>
                </div>
                : null}
            <div className="row">
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">First Name</label>
                    <input type="text" className="form-control" value={createLabel.firstName} onChange={e => userChange(e, 'firstName')} />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">Last Name</label>
                    <input type="text" className="form-control" value={createLabel.lastName} onChange={e => userChange(e, 'lastName')} />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">Email</label>
                    <input type="email" className="form-control" value={createLabel.email} onChange={e => userChange(e, 'email')} />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">Phone(+15555555555)</label>
                    <input type="text" className="form-control" value={createLabel.phone} onChange={e => userChange(e, 'phone')} />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label className="font-12px m-0">D.O.B</label>
                    <DatePicker
                        value={createLabel.dob}
                        id="dob"
                        onChange={e => userChange(e, 'dob')}
                    />
                </div>
                {loginUserData !== undefined && loginUserData[0].roles === ROLES.ADMIN ?
                    <>
                        {allLabels.length > 0 ?
                            <div className="col-12 col-md-6 form-group">
                                <label className="font-12px m-0">Labels</label>
                                <div className="multiselect">
                                    <div className="selectBox" onClick={e => showLabelsHandler(e, 'inner')}>
                                        {/* {allLabels.map((data, i) => {
                                            return (
                                                <span key={i}>
                                                    {editLabelRoles.labelsForAssign.includes(data.id.toString()) ?
                                                        : null
                                                    }
                                                </span>
                                            )
                                        })} */}
                                        <select className="form-control">
                                            <option>
                                                {editLabelRoles.labelNameForAssign.length > 0 ?
                                                    editLabelRoles.labelNameForAssign.join()
                                                    : "Labels"}
                                            </option>
                                        </select>
                                        <div className="overSelect"></div>
                                    </div>
                                    {showLabelsCheckboxes ?
                                        <div className="assign-labels" onClick={e => e.stopPropagation()}>
                                            {allLabels.map((data, i) => {
                                                return (
                                                    <div key={i} className="custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            value={data.id}
                                                            onChange={e => assignLabelChange(e, 'labels')}
                                                            checked={editLabelRoles.labelsForAssign.includes(data.id.toString()) ? true : false}
                                                        />
                                                        <span>&nbsp;&nbsp;{data.name}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        : null}
                                </div>
                            </div>
                            : null}
                        <div className="col-12 col-md-6 form-group">
                            <label className="font-12px m-0">Role</label>
                            <select className="form-control" value={editLabelRoles.roles[0]} onChange={e => assignLabelChange(e, 'roles')}>
                                <option value="">Select Role</option>
                                <option value={ROLES.CORP_ADMIN}>Corp Admin</option>
                                <option value={ROLES.ADMIN}>Account Admin</option>
                                <option value={ROLES.USER}>Account User</option>
                            </select>
                        </div>
                    </>
                    : null}
            </div>
            <div className="row form-group">
                <div className="col-12 inline-right-btn">
                    <button className="plain-btn cancel-btn" onClick={e => labelPopupHandler(e, 'close')}>Cancel</button>
                    <button className="plain-btn save-btn" onClick={e => labelPopupHandler(e, 'save')}>Save</button>
                </div>
            </div>
        </div>
    )

export default CreateLabelPopup;
