import React from 'react';
import DollerIcon from '../../../images/dollar.png'

const App = () => (
  <div className="dashboard-left-nav">
    <ul className="left-nav-items">
      <li>
        <img src={DollerIcon} alt="Doller" />
      </li>
    </ul>
  </div>
)

export default App;
